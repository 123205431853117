<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <SearchBar @search="onSearch" searchLabel="Search by task name..." />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <TaskFilter @applyFilters="onApplyFilters" />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="exportItems()"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Export</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels
          v-model="expanded"
          multiple
          active-class="msaBlue white--text"
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              hide-actions
              v-slot="{ open }"
              :style="headerStyles(0)"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col class="grow pl-4 font-weight-medium"> To Do </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TasksTable
                :index="0"
                :filters="filters"
                :key="toDoTableKey"
                @refreshCompletedTasks="completedTableKey++"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              hide-actions
              v-slot="{ open }"
              :style="headerStyles(1)"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col class="grow pl-4 font-weight-medium"> Completed </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TasksTable
                :index="1"
                :filters="filters"
                :key="completedTableKey"
                @refreshToDoTasks="toDoTableKey--"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import TaskFilter from '@/components/TrainingTasks/TaskFilter.vue';
import TasksTable from '@/components/TrainingTasks/TasksTable.vue';
import { phantomAccessCheck } from '../global/functions.js';
export default {
  name: 'TasksPage',
  components: {
    SearchBar,
    TaskFilter,
    TasksTable,
  },
  data() {
    return {
      filters: {
        ...this.$constants.TASK_FILTER,
      },
      expanded: [0],
      completedTableKey: 9999,
      toDoTableKey: 9998,
    };
  },
  computed: {
    isFilterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.TASK_FILTER)
      );
    },
    headerStyles() {
      return (index) =>
        this.expanded.includes(index)
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
  },
  methods: {
    onSearch(data) {
      this.filters.taskName = data;
      this.getTasks();
    },
    onApplyFilters(filters) {
      this.filters = { ...filters };
      this.getTasks();
    },
    exportItems() {
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      const params = {
        userId: this.$store.getters.user.id,
        isFilterApplied: this.isFilterApplied,
        filters: this.filters,
        loaderText: 'loading...',
      };
      const url = 'export-tasks-by-user?format=json';
      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.downloadFile(response.data, 'Tasks Export.xlsx');
      });
    },
  },
  mounted() {
    if (this.$route.name == 'Tasks' && !phantomAccessCheck(this, 'Tasks')) {
      return;
    }
  },
};
</script>
