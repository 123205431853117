<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="count"
        disable-filtering
        disable-sort
        checkbox-color="msaBlue"
        item-key="id"
        class="custom-list"
        :style="{ 'border-bottom': 'thin solid rgba(0, 0, 0, 0.12)' }"
        :options.sync="options"
        :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
      >
        <template v-slot:[`item.taskTypeId`]="{ item }">
          <v-checkbox
            v-if="item.taskTypeId == $constants.TASK_TYPES.REGULAR"
            dense
            hide-details
            class="ma-0 pl-2"
            v-model="item.isDone"
            :true-value="1"
            :false-value="0"
            @click="updateTask(item)"
            :disabled="!isAllowedToUpdateTask"
          >
          </v-checkbox>
          <v-icon :color="iconColor(item)" v-else>
            {{ getIcon(item) }}
          </v-icon>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span
            v-if="
              item.taskTypeId != $constants.TASK_TYPES.REGULAR &&
              item.isDocumentAssigned !== 0
            "
            class="clickable"
            @click="goToDetail(item)"
          >
            {{ item.name }}
          </span>
          <span v-html="taskName(item)" v-else></span>
        </template>
        <template v-slot:[`item.dueDate`]="{ item }">
          <span :class="isTaskOverDue(item.dueDate) ? 'red--text' : ''">
            {{ item.dueDate | filterAsLocalDate }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { DateTime } from 'luxon';
export default {
  name: 'TasksTable',
  props: {
    index: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  watch: {
    filters: {
      handler() {
        this.options.page = 1;
        this.getItems();
      },
      deep: true,
    },
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      items: [],
      count: 0,
      options: this.$helpers.defaultTableOptions(),
      headers: [
        {
          text: 'Type',
          align: 'center',
          value: 'taskTypeId',
          class: 'lightGrey',
          width: '20px',
          sortable: false,
        },
        {
          text: 'Task',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Group',
          value: 'groupName',
          align: 'center',
          width: '40%',
          class: 'lightGrey',
        },
        {
          text: 'Due Date',
          value: 'dueDate',
          align: 'center',
          width: '110px',
          class: 'lightGrey',
        },
      ],
    };
  },
  computed: {
    isTaskOverDue() {
      return (dueDate) => {
        let localDueDate = this.$options.filters.filterAsLocalDate(dueDate);
        localDueDate = DateTime.fromISO(localDueDate);
        const now = DateTime.local();
        return localDueDate < now;
      };
    },
    taskName() {
      return (task) => {
        return '<span>' + this.$helpers.linkify(task.name) + '</span>';
      };
    },
    isToDo() {
      if (!this.index) {
        return true;
      }

      return false;
    },
    isAllowedToUpdateTask() {
      if (this.$store.getters.isGroupAdministrator) {
        return true;
      }

      if (this.$store.getters.isWebAdmin) {
        return true;
      }

      return false;
    },
  },
  methods: {
    getItems() {
      const params = {
        loaderText: 'loading...',
        filters: this.filters,
        options: this.options,
      };
      const url = this.isToDo
        ? 'get-todo-tasks-by-user'
        : 'get-completed-tasks-by-user?format=json';

      this.$axios.post(url, params).then((response) => {
        this.count = response.data.count;

        if (this.isToDo) {
          this.items = response.data.items.map((item) => ({
            ...item,
            isSelected: false,
          }));
          return;
        }

        this.items = response.data.items;
      });
    },
    iconColor(task) {
      let color = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          color = 'red';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          color = 'msaBlue';
          break;
        default:
          color = 'green';
      }
      return color;
    },
    getIcon(task) {
      let icon = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          icon = 'mdi-file-pdf-box';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          icon = 'mdi-school';
          break;
        default:
          icon = '';
      }
      return icon;
    },
    async redirectToLms() {
      var windowReference = window.open();
      let r = await this.$axios.post('get-transfer-token?format=json', {
        loaderText: 'Connecting....',
      });
      const url = `${process.env.VUE_APP_BASE_URL}security-service/cht-to-lms?format=json&redirect=EmployeeMyCourses&statuses=2,1&token=${r.data}`;
      windowReference.location = url;
    },
    redirectToAssignedDocumentDetail(taskId) {
      const params = {
        loaderText: 'loading...',
        id: taskId,
      };
      const url = 'get-redirect-info-for-document-task?format=json';
      this.$axios.post(url, params).then((response) => {
        this.$store.commit('updateSelectedAssignedDocument', response.data);
        this.$router.push({
          name: 'AssignedDocumentDetails',
          params: { id: response.data.id },
        });
      });
    },
    goToDetail(task) {
      if (task.taskTypeId == 3) {
        this.redirectToLms();
      } else {
        this.redirectToAssignedDocumentDetail(task.id);
      }
    },
    updateTask(task) {
      const updateParams = {
        loaderText: 'loading...',
        id: task.id,
        snackText: task.isDone ? 'Task Completed.' : 'Task Updated.',
      };

      const url = 'update-task-is-done?format=json';

      this.$axios.post(url, updateParams).then(() => {
        this.items = this.items.filter((i) => i.id != task.id);

        task.isDone
          ? this.$emit('refreshCompletedTasks')
          : this.$emit('refreshToDoTasks');
      });
    },
  },
};
</script>

<style>
.custom-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
